import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { ProCard } from '@ant-design/pro-components';
import http from '../../utils/http';
import {
  handleErrorResponse,
  showNotification,
  NotificationType,
} from '../../utils';
import { apiRoutes } from '../../routes/api';
import { BreadcrumbProps, Button, Input, Select, Space } from 'antd';
import BasePageContainer from '../layout/PageContainer';
import {} from '../../interfaces/models/reportLog';
import { webRoutes } from '../../routes/web';
import { Report } from '../../interfaces/models/report';

const breadcrumb: BreadcrumbProps = {
  items: [
    {
      key: webRoutes.home,
      title: (
        <Link to={webRoutes.home}>
          <HomeOutlined />
        </Link>
      ),
    },
    {
      key: webRoutes.prompts,
      title: <Link to={webRoutes.prompts}>Prompts</Link>,
    },
  ],
};

interface PromptData {
  [key: string]: any; // or replace 'any' with the type of values in the 'promptData' object
}

const Prompts = () => {
  const [context, setContext] = useState('');
  const [reportList, setReportList] = useState([
    { value: 0, label: 'Select Report', disabled: true },
  ]);
  const [report, setReport] = useState(0);
  const [flag, setFlag] = useState(true);
  const [promptData, setPromptData] = useState<PromptData>({});

  useEffect(() => {
    http
      .get(`${apiRoutes.reports}/list`)
      .then((response) => {
        const reportTypes: [Report] = response.data.data;
        const reportData: any = reportTypes.map((item) => ({
          value: item._id,
          label: item.name,
        }));

        setReportList(() =>
          [{ value: 0, label: 'Select Report', disabled: true }].concat(
            reportData
          )
        );
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  }, []);

  useEffect(() => {
    http
      .get(`${apiRoutes.prompt}/list`)
      .then((response) => {
        const promptList: any = response.data.data;
        const promptObj: any = {};

        promptList.forEach(
          (item: any) => (promptObj[item.report_id] = item.context)
        );
        setPromptData(promptObj);
      })
      .catch((error) => {
        handleErrorResponse(error);
      });
  }, []);

  const handlePrompt = () => {
    http
      .put(`${apiRoutes.prompt}/update/${report}`, { context: context })
      .then((res) => {
        if (res.status === 200) {
          setPromptData((prev) => ({ ...prev, [report]: context }));
          showNotification(
            'Success',
            NotificationType.SUCCESS,
            res.data.message
          );
        }
      })
      .catch((error) => handleErrorResponse(error));
  };

  return (
    <BasePageContainer breadcrumb={breadcrumb}>
      <ProCard>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <Select
            style={{ width: 300 }}
            options={reportList}
            value={report}
            onChange={(value: any) => {
              setReport(value);
              setContext(promptData[value]);
            }}
          />
          <Input.TextArea
            showCount
            style={{
              height: 250,
              overflow: 'hidden',
              marginBottom: 24,
              fontFamily: 'monospace',
            }}
            onChange={(e) => {
              setContext(e.target.value);
              setFlag(false);
            }}
            value={context}
          />
          <Button disabled={flag} onClick={handlePrompt}>
            Save
          </Button>
        </Space>
      </ProCard>
    </BasePageContainer>
  );
};

export default Prompts;
