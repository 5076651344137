import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { ProCard } from '@ant-design/pro-components';
import http from '../../utils/http';
import {
  handleErrorResponse,
  showNotification,
  NotificationType,
} from '../../utils';
import { apiRoutes } from '../../routes/api';
import { BreadcrumbProps, Button, Input, Space } from 'antd';
import BasePageContainer from '../layout/PageContainer';
import {} from '../../interfaces/models/reportLog';
import { webRoutes } from '../../routes/web';

const breadcrumb: BreadcrumbProps = {
  items: [
    {
      key: webRoutes.home,
      title: (
        <Link to={webRoutes.home}>
          <HomeOutlined />
        </Link>
      ),
    },
    {
      key: webRoutes.player,
      title: <Link to={webRoutes.player}>Player</Link>,
    },
  ],
};

const Player = () => {
  const [flag, setFlag] = useState(false);
  const [fullName, setFullName] = useState('');
  const [output, setOutput] = useState('');

  const handleQuery = () => {
    setFlag(true);
    http
      .post(`${apiRoutes.player}`, { fullName })
      .then((res) => {
        setFlag(false);
        if (res.status === 200) {
          setOutput(res.data.data);
          showNotification(
            'Success',
            NotificationType.SUCCESS,
            res.data.message
          );
        }
      })
      .catch((error) => handleErrorResponse(error));
  };

  return (
    <BasePageContainer breadcrumb={breadcrumb}>
      <ProCard>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <div className="flex gap-2">
            <Input
              addonBefore="Full Name:"
              placeholder="Full Name"
              value={fullName}
              onChange={(e: any) => setFullName(e.target.value)}
            />
            <Button loading={flag} onClick={handleQuery}>
              Search
            </Button>
          </div>
          <div>{`ESPN ID: ${output}`}</div>
        </Space>
      </ProCard>
    </BasePageContainer>
  );
};

export default Player;
