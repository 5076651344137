import React from 'react';
import { Outlet } from 'react-router';

const AuthLayout = () => {
  return (
    <div
      className="bg-cover bg-center h-screen relative text-5xl"
      style={{ backgroundImage: `url('/login-bg.png')` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-70"></div>
      <div className="w-full max-w-xs md:w-2/5 sm:w-4/5 absolute top-1/2 left-1/2 z-50 text-left p-12 transform -translate-x-1/2 -translate-y-1/2 bg-white bg-opacity-10 shadow-lg">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthLayout;
