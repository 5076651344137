import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { ProCard } from '@ant-design/pro-components';

import {
  BreadcrumbProps,
  Space,
  Select,
  Button,
  Modal,
  Input,
  Card,
  Divider,
} from 'antd';
import BasePageContainer from '../layout/PageContainer';
import { webRoutes } from '../../routes/web';

import http from '../../utils/http';
import { handleErrorResponse } from '../../utils';
import { apiRoutes } from '../../routes/api';
import { convertToEST } from '../../utils/timeConvert';

const breadcrumb: BreadcrumbProps = {
  items: [
    {
      key: webRoutes.home,
      title: (
        <Link to={webRoutes.home}>
          <HomeOutlined />
        </Link>
      ),
    },
    {
      key: webRoutes.reportGenerate,
      title: <Link to={webRoutes.reportGenerate}>Report Generate</Link>,
    },
  ],
};

const ReportGenerate = () => {
  const [IDloading, setIDLoading] = useState(false);
  const [promptLoading, setPromptLoading] = useState(false);
  const [league, setLeague] = useState('0');
  const [position, setPosition] = useState(0);

  const [stateRun, setStateRun] = useState(false);

  const [userName, setUserName] = useState('');
  const [season, setSeason] = useState('');
  const [leagueList, setLeaugeList] = useState([
    { value: '0', label: 'Select LeagueID', disabled: true },
  ]);

  const [week, setWeek] = useState(0);
  const weekList = useMemo(() => {
    const save: any = [{ value: 0, label: 'Week', disabled: true }];
    for (let i = 1; i <= 18; i++) {
      save.push({ value: i, label: i });
    }
    return save;
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [jsonData, setJsonData] = useState('');
  const [reportData, setReportData] = useState('');
  const [audioLoading, setAudioLoading] = useState(false);

  const [audioURL, setAudioURL] = useState('');
  const [voiceOptions, setVoiceOptions] = useState([]);
  const [voiceID, setVoiceID] = useState('');

  const [openAiCost, setOpenAiCost] = useState('');
  const [elevenlabsCost, setElevenlabsCost] = useState('');

  const options = [
    {
      value: 0,
      label: 'Select report type',
      disabled: true,
    },
    {
      value: 1,
      label: 'Pre-Matchup Analysis Report',
    },
    {
      value: 2,
      label: 'Post-Matchup Analysis Report',
    },
    {
      value: 3,
      label: 'Power Rankings Report',
    },
    {
      value: 4,
      label: 'Team News Analysis Report',
    },
    {
      value: 5,
      label: 'League Trade Analysis Report',
    },
    {
      value: 6,
      label: 'Team Analysis Report',
    },
    {
      value: 7,
      label: 'Team Trade Analysis Report',
    },
    {
      value: 8,
      label: 'Waiver Analysis Report',
    },
  ];
  const [sleeperTime, setSleeperTime] = useState(undefined);
  const [sleeperLoading, setSleeperLoading] = useState(false);

  useEffect(() => {
    (async () => {
      await http
        .get(`${apiRoutes.prompt}/sleeper/time`)
        .then((res) => setSleeperTime(res.data))
        .catch((err) => {
          console.log(err);
        });
    })();
  }, []);

  const buildSleeper = async () => {
    if (league) {
      setSleeperLoading(true);
      await http
        .post(`${apiRoutes.prompt}/sleeper/build`, {
          league: league,
          week: week,
        })
        .then((res) => setSleeperTime(res.data))
        .catch((err) => {
          console.log(err);
        });
      setSleeperLoading(false);
    }
  };

  useEffect(() => {
    if (league && position && week) {
      setStateRun(true);
    }
  }, [league, position, week]);

  const buildData = async () => {
    setStateRun(false);
    setPosition(0);
    setIDLoading(true);
    setJsonData('');
    setReportData('');
    await http
      .post(`${apiRoutes.prompt}/build`, {
        userName: userName,
        season: season,
      })
      .then((res) => {
        setIDLoading(false);
        let list = [];
        list = res.data?.map((item: any) => {
          return {
            value: item.league_id,
            label: `${item.name}(${item.season})`,
          };
        });
        setLeague('0');
        setLeaugeList(
          [{ value: '0', label: 'Select LeagueID', disabled: true }].concat(
            list
          )
        );
        setWeek(0);
      })
      .catch((err) => {
        handleErrorResponse(err);
        setIDLoading(false);
      });
  };

  const onSearch = () => {
    setPromptLoading(true);
    if (position === 1) {
      http
        .post(`${apiRoutes.prompt}/matchup/before`, {
          sleeperId: userName,
          league,
          week,
          report: 'Pre-Matchup Analysis Report',
        })
        .then((res) => {
          setJsonData(JSON.stringify(res.data.output));
          setOpenAiCost(res.data.openAiCost);
          setElevenlabsCost(res.data.elevenlabsCost);
          setReportData(res.data.completion);
          setPromptLoading(false);
        })
        .catch((err) => {
          handleErrorResponse(err);
          setPromptLoading(false);
        });
    }
    if (position === 2) {
      http
        .post(`${apiRoutes.prompt}/matchup/after`, {
          sleeperId: userName,
          league,
          week,
          report: 'Post-Matchup Analysis Report',
        })
        .then((res) => {
          setJsonData(JSON.stringify(res.data.output));
          setOpenAiCost(res.data.openAiCost);
          setElevenlabsCost(res.data.elevenlabsCost);
          setReportData(res.data.completion);
          setPromptLoading(false);
        })
        .catch((err) => {
          handleErrorResponse(err);
          setPromptLoading(false);
        });
    }
    if (position === 3) {
      http
        .post(`${apiRoutes.prompt}/rank`, {
          sleeperId: userName,
          league,
          week,
          report: 'Power Rankings Report',
        })
        .then((res) => {
          setJsonData(JSON.stringify(res.data.output));
          setOpenAiCost(res.data.openAiCost);
          setElevenlabsCost(res.data.elevenlabsCost);
          setReportData(res.data.completion);
          setPromptLoading(false);
        })
        .catch((err) => {
          handleErrorResponse(err);
        });
    }
    if (position === 4) {
      http
        .post(`${apiRoutes.prompt}/news`, {
          sleeperId: userName,
          league,
          week,
          report: 'Team News Analysis Report',
        })
        .then((res: any) => {
          setJsonData(JSON.stringify(res.data.output));
          setOpenAiCost(res.data.openAiCost);
          setElevenlabsCost(res.data.elevenlabsCost);
          setReportData(res.data.completion);
          setPromptLoading(false);
        })
        .catch((err: any) => {
          handleErrorResponse(err);
        });
    }
    if (position === 5) {
      http
        .post(`${apiRoutes.prompt}/trade/league`, {
          sleeperId: userName,
          league,
          week,
          report: 'League Trade Analysis Report',
        })
        .then((res: any) => {
          setJsonData(JSON.stringify(res.data.output));
          setOpenAiCost(res.data.openAiCost);
          setElevenlabsCost(res.data.elevenlabsCost);
          setReportData(res.data.completion);
          setPromptLoading(false);
        })
        .catch((err: any) => {
          handleErrorResponse(err);
        });
    }

    if (position === 6) {
      http
        .post(`${apiRoutes.prompt}/team`, {
          sleeperId: userName,
          league,
          week,
          report: 'Team Analysis Report',
        })
        .then((res: any) => {
          setJsonData(JSON.stringify(res.data.output));
          setOpenAiCost(res.data.openAiCost);
          setElevenlabsCost(res.data.elevenlabsCost);
          setReportData(res.data.completion);
          setPromptLoading(false);
        })
        .catch((err: any) => {
          handleErrorResponse(err);
        });
    }

    if (position === 7) {
      http
        .post(`${apiRoutes.prompt}/trade/team`, {
          sleeperId: userName,
          league,
          week,
          report: 'Team Trade Analysis Report',
        })
        .then((res: any) => {
          setJsonData(JSON.stringify(res.data.output));
          setOpenAiCost(res.data.openAiCost);
          setElevenlabsCost(res.data.elevenlabsCost);
          setReportData(res.data.completion);
          setPromptLoading(false);
        })
        .catch((err: any) => {
          handleErrorResponse(err);
        });
    }

    if (position === 8) {
      http
        .post(`${apiRoutes.prompt}/trade/waiver`, {
          sleeperId: userName,
          league,
          week,
          report: 'Waiver Analysis Report',
        })
        .then((res: any) => {
          setJsonData(JSON.stringify(res.data.output));
          setOpenAiCost(res.data.openAiCost);
          setElevenlabsCost(res.data.elevenlabsCost);
          setReportData(res.data.completion);
          setPromptLoading(false);
        })
        .catch((err: any) => {
          handleErrorResponse(err);
        });
    }

    // if (position === 8) {
    //   http
    //     .post(`${apiRoutes.prompt}/draft`, {
    //       league: league,
    //       week: week,
    //     })
    //     .then((res) => {
    //       setJsonData(res.data);
    //       setPromptLoading(false);
    //     })
    //     .catch((err) => {
    //       handleErrorResponse(err);
    //     });
    // }
  };

  const showModal = async () => {
    setIsModalOpen(true);
    const response = await http.get(`${apiRoutes.prompt}/voice_list`);
    const result = response.data.data;
    const save = result?.map((item: any) => {
      return {
        value: item['voice_id'],
        label: item['name'],
        audioURL: item['preview_url'],
      };
    });
    setVoiceOptions(save);
  };

  const handleDownload = () => {
    const a: any = document.createElement('a');
    const url = `${process.env.REACT_APP_API_URL}/api/admin/prompt/audio/download/audio`;
    a.href = url;
    a.download = url.split('/').pop();
    a.click();
  };

  const handleAudio = () => {
    setAudioLoading(true);
    http
      .post(`${apiRoutes.prompt}/generate_audio`, {
        text: reportData,
        voiceID: voiceID,
      })
      .then(() => {
        setAudioLoading(false);
      })
      .catch((err) => {
        handleErrorResponse(err);
      });
  };

  const tabList = [
    {
      key: 'sleeper',
      tab: 'Sleeper Data',
    },
    {
      key: 'report',
      tab: 'Report Data',
    },
  ];

  const contentList: Record<string, React.ReactNode> = {
    sleeper: (
      <Input.TextArea
        showCount
        style={{
          height: 400,
          overflow: 'hidden',
          marginBottom: 24,
          fontFamily: 'monospace',
        }}
        onChange={(e: any) => setJsonData(e.target.value)}
        value={jsonData}
        placeholder="JSON"
      />
    ),
    report: (
      <Input.TextArea
        showCount
        style={{
          height: 400,
          overflow: 'hidden',
          marginBottom: 24,
        }}
        onChange={(e: any) => setReportData(e.target.value)}
        value={reportData}
        placeholder="Report"
      />
    ),
  };

  const [activeTabKey, setActiveTabKey] = useState<string>('sleeper');

  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  return (
    <BasePageContainer breadcrumb={breadcrumb}>
      <ProCard>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <div className="flex gap-2">
            <Input
              addonBefore="UserName:"
              style={{ width: 320 }}
              value={userName}
              onChange={(e: any) => setUserName(e.target.value)}
            />
            <Input
              addonBefore="Season:"
              style={{ width: 320 }}
              value={season}
              onChange={(e: any) => setSeason(e.target.value)}
            />
            <Button type="dashed" loading={IDloading} onClick={buildData}>
              Get Data
            </Button>
          </div>
          <div className="flex gap-2 items-center">
            <Select
              style={{ width: 320 }}
              options={leagueList}
              value={league}
              onChange={(value: any) => setLeague(value)}
            />
            <Select
              style={{ width: 80 }}
              options={weekList}
              value={week}
              onChange={(value: any) => setWeek(value)}
            />
            {league && week ? (
              <>
                <span>Last time: {convertToEST(sleeperTime)}</span>
                <Button
                  type="dashed"
                  onClick={buildSleeper}
                  loading={sleeperLoading}
                >
                  Update
                </Button>
              </>
            ) : (
              ''
            )}
          </div>
          <div className="flex gap-2">
            <Select
              style={{ width: 320 }}
              value={position}
              options={options}
              onChange={(value: any) => setPosition(value)}
            />
            <Button
              onClick={onSearch}
              disabled={!stateRun}
              loading={promptLoading}
            >
              Report Generate
            </Button>
          </div>
          <Divider />
          <Card
            style={{ width: '100%' }}
            tabList={tabList}
            activeTabKey={activeTabKey}
            tabBarExtraContent={
              <div className="flex gap-4 items-center">
                <span>{`OpenAI: $${openAiCost}`}</span>
                <span>{`Elevenlabs: $${elevenlabsCost}`}</span>
                <Button type="dashed" onClick={showModal}>
                  Generate an audio file
                </Button>
                <Button type="dashed" onClick={handleDownload}>
                  Download an audio file
                </Button>
              </div>
            }
            onTabChange={onTabChange}
            tabProps={{
              size: 'middle',
            }}
          >
            {contentList[activeTabKey]}
          </Card>
        </Space>
        <Modal
          title="Generate an audio file"
          open={isModalOpen}
          footer={[
            <Button
              type="dashed"
              key="cancel"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>,
            <Button
              key="generate"
              type="dashed"
              loading={audioLoading}
              onClick={handleAudio}
            >
              Generate
            </Button>,
          ]}
          onCancel={() => setIsModalOpen(false)}
          onOk={() => setIsModalOpen(false)}
        >
          <Space
            direction="vertical"
            size="middle"
            style={{ display: 'flex', marginTop: 20 }}
          >
            <Select
              style={{ width: 200 }}
              options={voiceOptions}
              onChange={(value: any, option: any) => {
                setAudioURL(option.audioURL);
                setVoiceID(value);
              }}
              size="large"
            />

            {audioURL && <audio controls src={audioURL} />}
          </Space>
        </Modal>
      </ProCard>
    </BasePageContainer>
  );
};

export default ReportGenerate;
