import { webRoutes } from '../../routes/web';
import {
  UserOutlined,
  HistoryOutlined,
  BookOutlined,
  FileTextOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { TbPlayFootball } from 'react-icons/tb';

import { FaFootballBall } from 'react-icons/fa';

export const sidebar = [
  {
    path: webRoutes.users,
    key: webRoutes.users,
    name: 'Users',
    icon: <UserOutlined />,
  },
  {
    path: webRoutes.reportTypes,
    key: webRoutes.reportTypes,
    name: 'Report Types',
    icon: <BookOutlined />,
  },
  {
    path: webRoutes.reportLogs,
    key: webRoutes.reportLogs,
    name: 'Report Logs',
    icon: <HistoryOutlined />,
  },
  {
    path: webRoutes.reportGenerate,
    key: webRoutes.reportGenerate,
    name: 'Report Generate',
    icon: <FaFootballBall />,
  },
  {
    path: webRoutes.prompts,
    key: webRoutes.prompts,
    name: 'Prompts',
    icon: <FileTextOutlined />,
  },
  {
    path: webRoutes.news,
    key: webRoutes.news,
    name: 'News',
    icon: <StarOutlined />,
  },
  {
    path: webRoutes.player,
    key: webRoutes.player,
    name: 'Player',
    icon: <TbPlayFootball />,
  },
];
