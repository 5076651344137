export const webRoutes = {
  home: '/',
  login: '/login',
  logout: '/logout',
  users: '/users',
  reportTypes: '/report_type',
  prompts: '/prompts',
  reportLogs: '/report_log',
  reportGenerate: '/report_generate',
  news: '/news',
  player: '/player',
};
