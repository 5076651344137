import { API_URL } from '../utils';

export const apiRoutes = {
  login: `${API_URL}/api/admin/auth/login`,
  logout: `${API_URL}/logout`,
  users: `${API_URL}/api/admin/user`,
  reports: `${API_URL}/api/admin/report`,
  reportlogs: `${API_URL}/api/admin/reportlog/list`,
  prompt: `${API_URL}/api/admin/prompt`,
  news: `${API_URL}/api/admin/news`,
  player: `${API_URL}/api/admin/player`,
  reviews: `${API_URL}/unknown`,
  download: `${API_URL}/api/admin/reportlog/audio/download`,
};
