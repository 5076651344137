import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { ProCard } from '@ant-design/pro-components';
import http from '../../utils/http';
import {
  handleErrorResponse,
  showNotification,
  NotificationType,
} from '../../utils';
import { apiRoutes } from '../../routes/api';
import { BreadcrumbProps, Button, Input, List, Space } from 'antd';
import BasePageContainer from '../layout/PageContainer';
import {} from '../../interfaces/models/reportLog';
import { webRoutes } from '../../routes/web';

const breadcrumb: BreadcrumbProps = {
  items: [
    {
      key: webRoutes.home,
      title: (
        <Link to={webRoutes.home}>
          <HomeOutlined />
        </Link>
      ),
    },
    {
      key: webRoutes.news,
      title: <Link to={webRoutes.news}>News</Link>,
    },
  ],
};

interface News {
  id: number;
  title: string;
  url: string;
  pubDate: string;
  author: string;
  categories: Array<any>;
  source: object;
  players: Array<any>;
  content: string;
}

const PlayerNews = () => {
  const [flag, setFlag] = useState(false);
  const [newsData, setNewsData] = useState<News[]>([]);
  const [endDay, setEndDay] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [team, setTeam] = useState('');

  const handleQuery = () => {
    setFlag(true);
    http
      .post(`${apiRoutes.news}`, { end: endDay, firstName, lastName, team })
      .then((res) => {
        setFlag(false);
        if (res.status === 200) {
          setNewsData(res.data.data);
          showNotification(
            'Success',
            NotificationType.SUCCESS,
            res.data.message
          );
        }
      })
      .catch((error) => handleErrorResponse(error));
  };

  return (
    <BasePageContainer breadcrumb={breadcrumb}>
      <ProCard>
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
          <div className="flex gap-2">
            <Input
              addonBefore="End date:"
              placeholder="YYYY-MM-DD"
              value={endDay}
              onChange={(e: any) => setEndDay(e.target.value)}
            />
            <Input
              addonBefore="First name:"
              placeholder="First name"
              value={firstName}
              onChange={(e: any) => setFirstName(e.target.value)}
            />
            <Input
              addonBefore="Last name:"
              placeholder="Last name"
              value={lastName}
              onChange={(e: any) => setLastName(e.target.value)}
            />
            <Input
              addonBefore="Team:"
              value={team}
              onChange={(e: any) => setTeam(e.target.value)}
            />
            <Button loading={flag} onClick={handleQuery}>
              Search
            </Button>
          </div>
          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              onChange: (page) => {
                console.log(page);
              },
              pageSize: 3,
            }}
            dataSource={newsData}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <div>id: {item.id}</div>
                <div>title: {item.title}</div>
                <div>url: {item.url}</div>
                <div>pubDate: {item.pubDate}</div>
                <div>author: {item.author}</div>
                <div>categories: {JSON.stringify(item.categories)}</div>
                <div>source: {JSON.stringify(item.source)}</div>
                <div>players: {JSON.stringify(item.players)}</div>
                content:
                <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
              </List.Item>
            )}
          />
        </Space>
      </ProCard>
    </BasePageContainer>
  );
};

export default PlayerNews;
